<template>
  <div class="content_two_content">
    <div class="content_two_contentdiv" v-for="(item,v) in detects" :key="v">
      <div style="font-size:18px;margin-bottom:12px">
        <span style="margin-right:4px">第{{ item.times }}次{{ frequencyName }}</span>
        <template v-if="frequencyName === '核酸检测'">
          <el-tag v-if="item.taskType==1" type="warning" size="small">其他</el-tag>
          <el-tag v-else-if="item.type==1" type="success" size="small">居家监测</el-tag>
          <el-tag v-else-if="item.type==2" type="primary" size="small">居家隔离</el-tag>
          <el-tag v-else-if="item.type==3" type="primary" size="small">集中隔离</el-tag>
        </template>
        
      </div>
      <div style="font-size:14px;margin-bottom:8px; color: #666;">拟定采样时间：{{ item.collectDate }}</div>
      <div style="font-size:14px;margin-bottom:8px; color: #666;">采样时间：{{ item.date }}</div>
      <div style="font-size:14px; color: #666;margin-bottom:8px;">
        <span style="margin-right: 10px;" v-show="frequencyName === '核酸检测'">检测结果：{{item.labResult}}</span>
        <span style="margin-right: 10px;" v-show="frequencyName === '抗体检测'">
          <span>检测结果：IgG: {{ item.iggLabResult }}</span>
          <span style="margin-left: 14px;">IgM: {{ item.igmLabResult }}</span>
        </span>
      </div>
      <template v-if="frequencyName == '核酸检测'">
        <div style="font-size:14px;margin-bottom:8px; color: #666;">检测单位：{{ item.labOrg||'' }}</div>
        <div style="font-size:14px;margin-bottom:8px; color: #666;">报告时间：{{ item.reportTime||'' }}</div>
      </template>
      <template>
        <el-button style="padding: 0;" v-btn-permission="'008001002012'" size="medium" type="text"
          @click="inputResult(item)" v-show="!item.labResult && !item.iggLabResult && !item.igmLabResult">录入结果
        </el-button>
        <el-button style="margin-left: 10px; color: #ff5353; padding: 0;" v-btn-permission="'008001002013'"
          size="medium" type="text" @click="delResult(item)" v-show="item.status == 0">删除</el-button>
      </template>
    </div>

    <!--录入核酸结果 弹窗-->
    <InputNucleicAcid ref="InputNucleicAcidRef" input-type="居民详情" @refreshData="refreshData" />

    <!--录入抗体检测 弹窗-->
    <InputAntibodyAssay ref="InputAntibodyAssayRef" input-type="居民详情" @refreshData="refreshData" />
  </div>
</template>

<script>
  import InputNucleicAcid from '../../Taskmanagement/modules/InputNucleicAcid';
  import InputAntibodyAssay from '../../Taskmanagement/modules/InputAntibodyAssay';
  import { messageBox } from '../../../utils/utils';
  import { collectDetectTask, collectAntibodyTask } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';

  export default {
    name: "DetectionInfo",
    components: { InputNucleicAcid, InputAntibodyAssay },
    data() {
      return {
        roles: JSON.parse(localStorage.getItem("roles"))
      }
    },
    props: {
      detects: {
        type: Array,
        default: function () {
          return []
        }
      },
      frequencyName: {
        type: String,
        default: ""
      },
      regId: [String, Number],
    },

    methods: {
      // 录入结果
      inputResult(item) {
        if (this.frequencyName === '核酸检测') {
          this.$refs.InputNucleicAcidRef.show(item, this.regId)
        } else if (this.frequencyName === '抗体检测') {
          this.$refs.InputAntibodyAssayRef.show(item, this.regId)
        }
      },

      // 删除录入记录
      delResult(item) {
        let message, result;
        if (this.frequencyName === '核酸检测') {
          message = "确定需要删除核酸检测录入计划吗？";
        } else if (this.frequencyName === '抗体检测') {
          message = "确定需要删除抗体检测录入计划吗？";
        }

        messageBox(async () => {
          let params = { taskId: item.taskId };

          if (this.frequencyName === '核酸检测') {
            result = await collectDetectTask({ params });
          } else if (this.frequencyName === '抗体检测') {
            result = await collectAntibodyTask({ params });
          }

          let { code, msg } = result.data;
          if (code == 200) {
            this.$message.success(msg)
            this.refreshData();
          } else {
            this.$message.error(msg)
          }
        }, 'warning', message)
      },

      // 用于刷新数据
      refreshData() {
        this.$parent.getIsolateDetailByRegId(this.regId);
      }
    }
  }
</script>

<style scoped lang="scss">
  .content_two_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .content_two_contentdiv {
    width: 290px;
    color: #333333;
    margin-bottom: 20px;
  }
</style>